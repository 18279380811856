<template>
  <div class="container-fuild row justify-content-center align-items-center m-0 p-lg-5 p-2 vh-100">

    <div class="col-xl-3 col-lg-3 col-md-7 col-sm-12 col-12 login-card p-4 rounded my-5">
      <div class="text-center">
        <span>
          <i class="bi bi-logo ">
            <img src="/logo.jpg" height="55" alt="">
          </i>
        </span>
        <br />
        <span class="fs-3">
          Bienvenue !
        </span>
      </div>
      <br />

      <form>
        <div>
          <label class="form-label" for=""> Nom d'utilisateur </label>
          <input v-model="auth.username" type="text" class="form-control"
            :class="usernameError ? 'border text-danger border-danger' : ''" />
          <div class="text-danger">
            {{ usernameError }}
          </div>
        </div>

        <br />
        <div>
          <label class="form-label"> Mot de passe </label>
          <div></div>
          <input v-model="auth.password" v-on:keyup.enter="login(auth)" :type="showPassword == true ? 'text' : 'password'"
            class="form-control" :class="passwordError ? 'border text-danger border-danger' : ''" />
          <div class="text-danger">
            {{ passwordError }}
          </div>
        </div>

        <br />
        <div>
          <div class="d-flex align-items-center">
            <input type="checkbox" name="" id="password_display" v-model="showPassword" />
            <label for="password_display" class="ms-2 mt-1 btn btn-link nav-link  fs-6">
              Afficher le mot de passe ?
            </label>
          </div>
        </div>
      </form>

      <br />
      <div class="col">
        <button type="submit" @click="login(auth)" class="btn btn-primary w-100 py-2 px-4">
          <span v-if="!progress"> Connecter </span>
          <span v-else-if="progress"> Vérification en cours </span>
        </button>
        <div class="text-center text-danger p-2">
          {{ error }}
        </div>
      </div>
    </div>

    <center class="text-white">
      © {{ currentYear }} <a href="https://www.peascom.ma" target="_blank" class="text-white">Peascom </a> - Tous droits réservés.
    </center>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      auth: {},
      progress: false,
      showPassword: false,
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    async login(auth) {
      await (this.progress = !this.progress);
      await this.$store.dispatch("auth/login", auth);
      setTimeout((this.progress = !this.progress), 6000);
    },
  },
  computed: {
    ...mapGetters("auth", {
      usernameError: "getUsernaneError",
      passwordError: "getPasswordError",
      error: "getError",
    }),
  },
  created() {
    try {
      if (this.$route.query.username && this.$route.query.password) {
        const username = this.$route.query.username;
        const password = this.$route.query.password;
        alert({ username, password });

        // Assuming your login method expects an object
        this.login({ username, password }); // Pass username and password as data
      }
    } catch (e) {
      console.log(e.message);
    }
  },
};
</script>

<style scoped>
.container-fuild {
  min-height: 100vh;
  /* background-color: red; */
  background-color: #343a40;
  background-image: -webkit-linear-gradient(-28deg,
      #F25826 0%,
      #F25826 60%,
      #343a40 60%,
      #6c757e 60%);
  background-image: linear-gradient(-28deg,
      #db9a84 0%,
      #F25826 60%,
      #343a40 60%,
      #343a40 60%);
}

.login-card {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  opacity: 0.8 !important;
}

@keyframes icon-animation {
  0% {
    color: #343a40;
  }

  50% {
    color: #F25826;
  }

  100% {
    color: #343a40;
  }
}

.icon {
  animation-name: icon-animation;
}

.fs-1 {
  font-size: 55px !important;
}

.form-control {
  padding: 0.6rem 0.8rem;
}
</style>
